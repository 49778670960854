<template>
  <div class="page">
    <div class="border-radius4 height50" style="background: #FAFAFA;display: flex;">
      <div style="background: #0768FD;color: white;display: flex;
            align-items: center;justify-content: center;cursor: pointer;"
           class="width110 font-size16 border-radius4004"
           @click="goBack">
        返回
      </div>
      <div
          style="color: black;font-weight: bold;display: flex; align-items: center;"
          class="font-size16 margin-left30">
        添加屏幕控制卡
      </div>
    </div>
    <div class="add-parking-basic">
      <el-form
          ref="ruleForm"
          :model="ruleForm"
          :rules="rules"
          class="demo-ruleForm"
          label-width="200px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="SN码:" prop="name" required>
              <el-input
                  v-model="ruleForm.name"
                  placeholder="请输入-设备SN码"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="选择车场:" prop="name" required>
              <el-input
                  v-model="ruleForm.name"
                  placeholder="请输入-车场名称"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="安装地址:" prop="name">
              <el-input
                  v-model="ruleForm.name"
                  placeholder="请输入安装地址"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="选择模板:" prop="name" required>
              <el-select
                  v-model="value"
                  placeholder="请选择模板"
                  style="width: 100%"
              >
                <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="选择内容:" prop="name">
              <el-input
                  v-model="ruleForm.name"
                  placeholder="请根据所选模板输入显示内容"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="设置备注:" prop="name">
              <el-input
                  v-model="ruleForm.name"
                  placeholder="请输入设备备注"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-form-item>
          <div
              style="
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
              "
          >
            <el-button class="xxd-button_save" type="primary" @click="submitForm('ruleForm')"
            >保存
            </el-button>
          </div>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  name: "guideCtrlScreen",
  data() {
    return {
      ruleForm: [
        {
          name: "",
        },
      ],
      value: "",
      options: [
        {
          // value: '123',
          // label: '123'
        },
      ],
    };
  },
  components: {},
  methods: {
    goBack() {
      // this.$router.back();
      this.$emit("goback");
    },
  },
  mounted() {
  },
};
</script>

<style lang="less" scoped>
.add-parking-basic {
  background: white;
  border-radius: 5px;
  padding: 18px;
}

.tabs-wrap {
  position: relative;

  .back-button {
    position: absolute;
    right: 20px;
    top: 0;
    font-size: 15px;
    font-weight: normal;
    display: flex;
    flex-direction: row;
    align-items: center;
    line-height: 18px;

    i {
      font-size: 18px;
    }
  }
}
</style>
