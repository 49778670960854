<template>
  <div class="page">
    <div class="search-box section">
      <el-form
          ref="query"
          :inline="true"
          :model="query"
          size="medium"
          style="position: relative"
      >
        <!-- <el-form-item prop="regionName">
          <el-cascader
            placeholder="请选择区域"
            v-model="query.regionName"
            :options="options"
            :props="{ checkStrictly: true }"
            clearable
            style="width: 300px"
          ></el-cascader>
        </el-form-item> -->
        <el-form-item prop="equipmentName">
          <el-input
              v-model="query.equipmentName"
              placeholder="请输入设备SN码"
          />
        </el-form-item>
        <el-form-item prop="onlineStatus">
          <el-select
              v-model="query.onlineStatus"
              clearable
              placeholder="请选择在线状态"
          >
            <el-option label="在线" value="1"></el-option>
            <el-option label="异常" value="2"></el-option>
            <el-option label="无状态" value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="EquipmentType">
          <el-select
              v-model="query.EquipmentType"
              clearable
              placeholder="请选择设备类型"
          >
            <el-option label="诱导屏-WS" value="1"></el-option>
            <el-option label="广告诱导屏" value="2"></el-option>
            <el-option label="余位诱导屏" value="3"></el-option>
            <el-option label="屏幕控制卡" value="4"></el-option>
            <el-option label="LED广告屏" value="5"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="OndutyStatus">
          <el-select
              v-model="query.OndutyStatus"
              clearable
              placeholder="请选择值守状态"
          >
            <el-option label="值守" value="1"></el-option>
            <el-option label="拆除" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="ParkingName">
          <el-input
              v-model="query.ParkingName"
              placeholder="请输入车场名称"
          />
        </el-form-item>
        <el-form-item prop="InstallName">
          <el-input
              v-model="query.InstallName"
              placeholder="请输入安装地址"
          />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="search">搜索</el-button>
          <el-button @click="doReset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>

    <!--信息统计框-->
    <div class="search-box section page-header height50 padding20-32">
      <div class="content" style="margin-left: 0;">
        <div class="content-item">
          <div class="title">信息条数：</div>
          <div class="text">{{ pagination.totalSize || '0' }}</div>
        </div>
      </div>
    </div>

    <div class="content-box section">
      <div class="row_new padding-bottom19">
        <el-dropdown placement="right-start" trigger="click" @command="handleCommand">
          <el-button type="primary" size="small" @click="goAdd">新增</el-button>
          <el-dropdown-menu slot="dropdown" style="width: 80px">
            <el-dropdown-item command="toguideAd">广告诱导屏</el-dropdown-item>
            <el-dropdown-item command="toguideYuwei">余位诱导屏</el-dropdown-item>
            <el-dropdown-item command="toguideCtrlScreen">屏幕控制卡</el-dropdown-item>
            <el-dropdown-item command="toguideWS">诱导屏-WS</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div>
        <el-table
            v-loading="isLoading"
            :data="dataList"
            border
            class="common-table"
            size="medium"
            style="width: 100%"
        >
          <el-table-column label="SN" prop="SN" width="255px"></el-table-column>
          <el-table-column
              label="在线状态"
              prop="onlineStatus1"
              width="100px"
          ></el-table-column>
          <el-table-column
              label="设备类型"
              prop="EquipmentType1"
              width="250px"
          ></el-table-column>
          <el-table-column
              label="车场名称"
              prop="ParkingName1"
              width="250px"
          ></el-table-column>
          <el-table-column
              label="区域"
              prop="Region"
              width="255px"
          ></el-table-column>
          <el-table-column
              label="安装地址"
              prop="InstallName1"
              width="250px"
          ></el-table-column>
          <el-table-column
              label="状态"
              prop="Status"
              width="70px"
          ></el-table-column>
          <el-table-column align="center" label="操作" width="200px">
            <template>
              <el-button
                  size="small common-text"
                  type="text"
                  @click="toDetail()"
              >详情
              </el-button
              >
              <el-button size="small common-text" type="text">
                <el-popover placement="bottom" trigger="hover">
                  <div
                      style="
                      color: #0768FD;
                      cursor: pointer;
                      display: flex;
                      justify-content: space-between;
                    "
                  >
                    <span class="el-dropdown-link" @click="toguideEdit()">
                      编辑
                    </span>
                    <span class="el-dropdown-link"> 取消 </span>
                  </div>
                  <span slot="reference" class="el-dropdown-link"> 更多 </span>
                </el-popover>
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pagination-box">
        <el-pagination
            :current-page="pagination.pageNo"
            :page-size="pagination.pageSize"
            :total="pagination.totalSize"
            background
            layout="sizes, prev, pager, next, jumper"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </div>
    <!-- 弹出详情页 -->
    <FullScreenDialog ref="screenDialog_detail">
      <div class="page">
        <GuideDetail @goback="goBack('screenDialog_detail')"/>
      </div>
    </FullScreenDialog>
    <!-- 弹出编辑页 -->
    <FullScreenDialog ref="screenDialog_edit">
      <div class="page">
        <GuideEdit @goback="goBack('screenDialog_edit')"/>
      </div>
    </FullScreenDialog>
    <!-- 弹出广告诱导屏 -->
    <FullScreenDialog ref="screenDialog_Ad">
      <div class="page">
        <GuideAd @goback="goBack('screenDialog_Ad')"/>
      </div>
    </FullScreenDialog>
    <!-- 弹出余位诱导屏 -->
    <FullScreenDialog ref="screenDialog_yuwei">
      <div class="page">
        <GuideYuwei @goback="goBack('screenDialog_yuwei')"/>
      </div>
    </FullScreenDialog>
    <!-- 弹出屏幕控制卡 -->
    <FullScreenDialog ref="screenDialog_ctrlscr">
      <div class="page">
        <GuideCtrlScreen @goback="goBack('screenDialog_ctrlscr')"/>
      </div>
    </FullScreenDialog>
    <!-- 弹出诱导屏WS -->
    <FullScreenDialog ref="screenDialog_WS">
      <div class="page">
        <GuideWS @goback="goBack('screenDialog_WS')"/>
      </div>
    </FullScreenDialog>
  </div>
</template>

<script>
import BaseMixin from "@/mixins/base";
import FullScreenDialog from "@/components/FullScreenDialog";
import GuideDetail from "../guide_screen/guideDetail";
import GuideEdit from "../guide_screen/guideEdit";
import GuideAd from "../guide_screen/guideAd";
import GuideYuwei from "../guide_screen/guideYuwei";
import GuideCtrlScreen from "../guide_screen/guideCtrlScreen";
import GuideWS from "../guide_screen/guideWs";

export default {
  name: "guide-sc",
  mixins: [BaseMixin],
  components: {
    FullScreenDialog,
    GuideDetail,
    GuideEdit,
    GuideAd,
    GuideYuwei,
    GuideCtrlScreen,
    GuideWS,
  },
  data() {
    return {
      query: {
        regionName: "",
        equipmentName: "",
        onlineStatusPort: "",
        EquipmentType: "",
        OndutyStatus: "",
        ParkingName: "",
        InstallName: "",
      },
      options: [
        {
          value: "xg",
          label: "香港特别行政区",
          children: [
            {
              value: "xj",
              label: "新界",
              // children: [{
              //   value: 'jl',
              //   label: '九龙'
              // },]
            },
            {
              value: "jiulong",
              label: "九龙",
            },
            {
              value: "xgd",
              label: "香港岛",
              children: [
                {
                  value: "nq",
                  label: "南区",
                },
                {
                  value: "zxq",
                  label: "中西区",
                },
                {
                  value: "wzq",
                  label: "湾仔区",
                },
                {
                  value: "dq",
                  label: "东区",
                },
              ],
            },
          ],
        },
      ],
      dataList: [],
    };
  },
  methods: {
    toDetail() {
      this.$refs.screenDialog_detail.show();

      // this.$router.push({
      //   path: "/resource/guide_screen/guideDetail",
      // });
    },
    // hideDialog () {
    //   this.$refs.screenDialog_detail.hide();
    // },
    toguideEdit() {
      this.$refs.screenDialog_edit.show();

      // this.$router.push({
      //   path: "/resource/guide_screen/guideEdit",
      // });
    },

    goBack(refName) {
      this.$refs[refName].hide();
    },

    search() {
    },

    handleCommand(command) {
      switch (command) {
        case "toguideAd":
          this.$refs.screenDialog_Ad.show();
          break;
        case "toguideYuwei":
          this.$refs.screenDialog_yuwei.show();
          break;
        case "toguideCtrlScreen":
          this.$refs.screenDialog_ctrlscr.show();
          break;
        case "toguideWS":
          this.$refs.screenDialog_WS.show();
          break;
      }
    },
  },
};
</script>

<style lang="less">
/*******************************改变了这个弹出框的宽度 */
.el-popover {
  min-width: 70px !important;
}
</style>
