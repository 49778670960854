<template>
  <div class="page">
      <div class="border-radius4 height50" style="background: #FAFAFA;display: flex;">
        <div style="background: #0768FD;color: white;display: flex;
            align-items: center;justify-content: center;cursor: pointer;"
             class="width110 font-size16 border-radius4004"
             @click="goBack">
          返回
        </div>
        <div
             style="color: black;font-weight: bold;display: flex; align-items: center;"
             class="font-size16 margin-left30">
          添加广告诱导屏
        </div>
      </div>
      <div class="add-parking-basic">
        <el-form
            ref="ruleForm"
            :model="ruleForm"
            :rules="rules"
            label-width="200px"
        >
          <el-row>
            <el-col :span="12">
              <el-form-item label="SN码:" prop="sn_name" required>
                <el-input
                    v-model="ruleForm.name"
                    placeholder="请输入-设备MAC地址"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="绑定车场:" prop="cc_name" required>
                <el-input
                    v-model="ruleForm.name"
                    placeholder="请输入绑定车场名称"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="安装地址:" prop="az_name">
                <el-input
                    v-model="ruleForm.name"
                    placeholder="请输入安装地址"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="显示车场名称:" prop="xscc_name" required>
                <el-input
                    v-model="ruleForm.name"
                    placeholder="请输入-显示车场名称"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="选择车场:" prop="name">
                <el-input
                    v-model="ruleForm.name"
                    placeholder="请输入-车场名称"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="选择泊位:" prop="name" required>
            <el-table
                ref="multipleTable"
                :data="tableData"
                :header-cell-style="{ background: '#f5f7fa', color: '#606266' }"
                border="true"
                style="width: 100%"
            >
              <el-table-column type="selection" width="55"></el-table-column>
              <el-table-column label="泊位编号" prop="date" width="212">
              </el-table-column>
              <el-table-column label="绑定设备" prop="name" width="212">
              </el-table-column>
            </el-table>
          </el-form-item>
          <el-form-item label="已选泊位:" required>
            <el-input
                v-model="textarea"
                :disabled="true"
                :rows="6"
                placeholder=""
                type="textarea"
            ></el-input>
          </el-form-item>

          <el-form-item>
            <div
                style="
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
              "
            >
              <el-button class="xxd-button_save" type="primary" @click="submitForm('ruleForm')"
              >保存</el-button>
            </div>
          </el-form-item>
        </el-form>
      </div>
  </div>
</template>

<script>
export default {
  name: "guideAd",
  data() {
    return {
      ruleForm: [
        {
          name: "",
        },
      ],
      rules: {
        sn_name: [{required: true, message: "请输入-设备SN码"}],
        xscc_name: [{required: true, message: "请输入-显示车场名称"}],
      },
    };
  },
  components: {},
  methods: {
    goBack() {
      // this.$router.back();
      this.$emit("goback");
    },
  },
  mounted() {
  },
};
</script>

<style lang="less" scoped>

.add-parking-basic {
  background: white;
  border-radius: 5px;
  padding: 18px;
}

.tabs-wrap {
  position: relative;

  .back-button {
    position: absolute;
    right: 20px;
    top: 0;
    font-size: 15px;
    font-weight: normal;
    display: flex;
    flex-direction: row;
    align-items: center;
    line-height: 18px;

    i {
      font-size: 18px;
    }
  }
}
</style>
