<template>
  <div class="page">
    <div class="content-box section">
      <el-tabs type="border-card">
        <el-tab-pane label="设备信息">
          <easy-card title="基础信息">
            <icon-button slot="extra" icon="return" @click.native="goBack"/>
            <el-descriptions :column="2" border class="margin-top">
              <el-descriptions-item>
                <template slot="label" style="width: 50px !important">
                  SN码
                </template>
                413311158c6a2ede
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label"> 设备编号</template>
                DC20220921111925315384
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label"> 设备类型</template>
                广告诱导屏
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label"> 值守状态</template>
                值守
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label"> 城区</template>
                贵州省贵阳市观山湖区
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label"> 车场名称</template>
                贵阳香山路2号路侧车场（Auto）
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label"> 车场编号</template>
                520111001003
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label"> 首次注册时间</template>
                2022-09-21 11:19:25
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label"> 尺寸</template>
                {{ dataList.leaseTitle }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label"> 规格</template>
                {{ dataList.leaseTitle }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label"> 设备经纬度</template>
                {{ dataList.leaseTitle }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label"> 安装地址</template>
                {{ dataList.leaseTitle }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label"> 最后一次心跳时间</template>
                2022-09-21 14:20:28
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label"> 显示车场名称</template>
                5楼测试路内车场
              </el-descriptions-item>
            </el-descriptions>
          </easy-card>
          <easy-card title="拓展信息">
            <el-descriptions :column="2" border class="margin-top">
              <el-descriptions-item>
                <template slot="label"> 软件版本</template>
                {{ dataList.orderCode }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label"> 固件版本</template>
                {{ dataList.paySettleAccountName }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label"> 算法版本</template>
                {{ dataList.parkingName }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label"> 硬件版本</template>
                {{ dataList.leaseTitle }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label"> 设备型号</template>
                {{ dataList.leaseTitle }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label"> 公网IP</template>
                {{ dataList.leaseTitle }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label"> 局域网IP</template>
                {{ dataList.leaseTitle }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label"></template>
              </el-descriptions-item>
            </el-descriptions>
          </easy-card>
        </el-tab-pane>

        <el-tab-pane label="安装记录">
          <el-descriptions :column="5" border direction="vertical" title="">
            <el-descriptions-item label="序号">1</el-descriptions-item>
            <el-descriptions-item label="操作日期"
            >2022-09-21
            </el-descriptions-item
            >
            <el-descriptions-item label="操作类型">安装</el-descriptions-item>
            <el-descriptions-item label="停车场名称"
            >贵阳香山路2号路侧车场（Auto）
            </el-descriptions-item
            >
            <el-descriptions-item label="停车场编号"
            >520111001003
            </el-descriptions-item
            >
          </el-descriptions>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
export default {
  name: "guideDetail",
  data() {
    return {
      id: "", //接受上个页面传来的id
      //查询到的订单详情信息列表
      dataList: {},
    };
  },
  components: {},
  methods: {
    //返回长租套餐页面
    goBack() {
      // this.$router.back();
      this.$emit("goback");
    },
  },
  mounted() {
  },
};
</script>

<style lang="less" scoped>
.title {
  line-height: 32px;
}
</style>
