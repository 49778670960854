<template>
  <div class="page">
    <div class="content-box section">
      <div
          style="
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          background-color: #edf0fd !important;
          border-radius: 5px;
          padding: 10px;
          font-size: 18px;
          box-sizing: border-box;
        "
      >
        <p>编辑广告诱导屏</p>
        <icon-button slot="extra" icon="return" @click.native="goBack"/>
      </div>
      <div
          style="
          width: 450px;
          height: 315px;
          margin: 15px auto;
          font-weight: bold;
        "
      >
        <el-form
            ref="ruleForm"
            :model="ruleForm"
            :rules="rules"
            class="demo-ruleForm"
            label-width="120px"
        >
          <el-form-item label="SN码:" prop="name" required>
            <el-input
                v-model="ruleForm.name"
                :disabled="true"
                placeholder="1d7972d10b2bafb8"
            ></el-input>
          </el-form-item>
          <el-form-item label="绑定车场:" prop="name" required>
            <el-input
                v-model="ruleForm.name"
                :disabled="true"
                placeholder="武威市凉州区医院生态停车场"
            ></el-input>
          </el-form-item>
          <el-form-item label="安装地址:" prop="name">
            <el-input
                v-model="ruleForm.name"
                placeholder="武威市凉州区医院南侧车场西出口"
            ></el-input>
          </el-form-item>
          <el-form-item label="显示车场名称:" prop="name" required>
            <el-input
                v-model="ruleForm.name"
                placeholder="凉州医院南生态停车场"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <div
                style="
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
              "
            >
              <el-button type="primary" @click="submitForm('ruleForm')"
              >确定
              </el-button
              >
              <el-button @click="resetForm('ruleForm')">取消</el-button>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "guideEdit",
  data() {
    return {
      ruleForm: [
        {
          name: "",
        },
      ],
    };
  },
  components: {},
  methods: {
    goBack() {
      // this.$router.back();
      this.$emit("goback");
    },
  },
  mounted() {
  },
};
</script>

<style lang="less" scoped>
</style>
