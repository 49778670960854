<template>
  <div class="page">
    <div class="border-radius4 height50" style="background: #FAFAFA;display: flex;">
      <div style="background: #0768FD;color: white;display: flex;
            align-items: center;justify-content: center;cursor: pointer;"
           class="width110 font-size16 border-radius4004"
           @click="goBack">
        返回
      </div>
      <div
          style="color: black;font-weight: bold;display: flex; align-items: center;"
          class="font-size16 margin-left30">
        添加诱导屏-WS
      </div>
    </div>
    <div class="add-parking-basic">
      <el-form
          ref="ruleForm"
          :model="ruleForm"
          :rules="rules"
          class="demo-ruleForm"
          label-width="120px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="设备SN码:" prop="name" required>
              <el-input
                  v-model="ruleForm.name"
                  placeholder="请输入-设备SN码"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="车场名称:" prop="name" required>
              <el-input
                  v-model="ruleForm.name"
                  placeholder="请输入-车场名称"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="安装地址:" prop="name">
              <el-input
                  v-model="ruleForm.name"
                  placeholder="请输入安装地址"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="尺寸:" prop="name" required>
              <el-input
                  v-model="input"
                  placeholder="宽度"
                  style="width: 40%"
              ></el-input>
              <span style="margin-left: 20px; margin-right: 20px">*</span>
              <el-input
                  v-model="input"
                  placeholder="高度"
                  style="width: 40%"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="规格:" prop="name" required>
              <el-select
                  v-model="valueall"
                  placeholder=""
                  style="width: 100%"
                  value-key="1"
              >
                <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="安装城区:" prop="name" required>
              <el-select
                  v-model="value_cq"
                  placeholder="请选择安装城区"
                  style="width: 100%"
                  value-key="2"
              >
                <el-option
                    v-for="item in options_cq"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="位置坐标:" prop="name" required>
              <el-input
                  v-model="ruleForm.name"
                  placeholder="拾取坐标"
                  @focus="cqisvalue"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
          </el-col>
        </el-row>

        <el-form-item>
          <div
              style="
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
              "
          >
            <el-button class="xxd-button_save" type="primary" @click="submitForm('ruleForm')"
            >保存
            </el-button>
          </div>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  name: "guideWS",
  data() {
    return {
      ruleForm: [
        {
          name: "",
        },
      ],
      valueall: "单色",
      options: [
        {
          value: "danse",
          label: "单色",
        },
        {
          value: "shuangse",
          label: "双色",
        },
        {
          value: "sanse",
          label: "三色",
        },
      ],
      value_cq: "",
      options_cq: [
        {
          value: "",
          label: "",
        },
      ],
    };
  },
  components: {},
  methods: {
    goBack() {
      // this.$router.back();
      this.$emit("goback");
    },
    cqisvalue() {
      // alert('123')
      if (this.value_cq === "") {
        this.$notify.error({
          title: "错误",
          message: "请选择城区（必须到区）",
        });
      }
    },
  },
  mounted() {
  },
};
</script>

<style lang="less" scoped>
.add-parking-basic {
  background: white;
  border-radius: 5px;
  padding: 18px;
}

.tabs-wrap {
  position: relative;

  .back-button {
    position: absolute;
    right: 20px;
    top: 0;
    font-size: 15px;
    font-weight: normal;
    display: flex;
    flex-direction: row;
    align-items: center;
    line-height: 18px;

    i {
      font-size: 18px;
    }
  }
}
</style>
