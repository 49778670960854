<template>
  <div class="page">
    <div class="border-radius4 height50" style="background: #FAFAFA;display: flex;">
      <div style="background: #0768FD;color: white;display: flex;
            align-items: center;justify-content: center;cursor: pointer;"
           class="width110 font-size16 border-radius4004"
           @click="goBack">
        返回
      </div>
      <div
          style="color: black;font-weight: bold;display: flex; align-items: center;"
          class="font-size16 margin-left30">
        添加余位诱导屏
      </div>
    </div>
    <div class="add-parking-basic">
      <el-form
          ref="ruleForm"
          :model="ruleForm"
          :rules="rules"
          class="demo-ruleForm"
          label-width="200px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="SN码:" prop="name" required>
              <el-input
                  v-model="ruleForm.name"
                  placeholder="请输入-设备MAC地址"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="绑定车场:" prop="name" required>
              <el-select
                  v-model="value_bangdincc"
                  placeholder="请选择"
                  style="width: 100%"
              >
                <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="安装地址:" prop="name">
              <el-input
                  v-model="ruleForm.name"
                  placeholder="请输入安装地址"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="展示名称:" prop="name" required>
              <el-input
                  v-model="ruleForm.name"
                  placeholder="请输入-该设备展示的设备名称"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="设备备注:" prop="name">
              <el-input
                  v-model="ruleForm.name"
                  placeholder="请输入设备备注"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="第1行展示:" prop="name">
              <el-select
                  v-model="value_show1"
                  clearable
                  placeholder="请选择展示内容"
                  style="width: 50%"
              >
                <el-option
                    v-for="item in options_show1"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="第2行展示:" prop="name">
              <el-select
                  v-model="value_show2"
                  clearable
                  placeholder="请选择展示内容"
                  style="width: 50%"
              >
                <el-option
                    v-for="item in options_show2"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="第3行展示:" prop="name">
              <el-select
                  v-model="value_show3"
                  clearable
                  placeholder="请选择展示内容"
                  style="width: 50%"
              >
                <el-option
                    v-for="item in options_show3"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="第4行展示:" prop="name">
              <el-select
                  v-model="value_show4"
                  clearable
                  placeholder="请选择展示内容"
                  style="width: 50%"
              >
                <el-option
                    v-for="item in options_show4"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="第5行展示:" prop="name">
              <el-select
                  v-model="value_show5"
                  clearable
                  placeholder="请选择展示内容"
                  style="width: 50%"
              >
                <el-option
                    v-for="item in options_show5"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="第6行展示:" prop="name">
              <el-select
                  v-model="value_show6"
                  clearable
                  placeholder="请选择展示内容"
                  style="width: 50%"
              >
                <el-option
                    v-for="item in options_show6"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="自定义设置:" prop="name">
              <el-button type="primary" @click="dialogFormVisible = true"
              >设置
              </el-button
              >
            </el-form-item>
          </el-col>
        </el-row>
        <el-dialog
            :modal-append-to-body="false"
            :visible.sync="dialogFormVisible"
            title="自定义设置"
            width="40%"
        >
          <el-form :model="diaform">
            <el-row>
              <el-col :span="12">
                <el-form-item
                    :label-width="formLabelWidth"
                    label="区域名称前缀："
                >
                  <el-input
                      v-model="diaform.qianzhui"
                      autocomplete="off"
                      style="width: 80%"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                    :label-width="formLabelWidth"
                    label="顶部背景颜色："
                >
                  <el-input
                      v-model="diaform.topbgcolor"
                      autocomplete="off"
                      style="width: 80%"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item
                    :label-width="formLabelWidth"
                    label="顶部字体颜色："
                >
                  <el-input
                      v-model="diaform.topfontcolor"
                      autocomplete="off"
                      style="width: 80%"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                    :label-width="formLabelWidth"
                    label="中部背景颜色："
                >
                  <el-input
                      v-model="diaform.centerbgcolor"
                      autocomplete="off"
                      style="width: 80%"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item
                    :label-width="formLabelWidth"
                    label="中部字体颜色："
                >
                  <el-input
                      v-model="diaform.centerfontcolor"
                      autocomplete="off"
                      style="width: 80%"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="dialogFormVisible = false">取 消</el-button>
            <el-button type="primary" @click="dialogFormVisible = false"
            >确 定
            </el-button
            >
          </div>
        </el-dialog>

        <el-form-item>
          <div
              style="
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
              "
          >
            <el-button class="xxd-button_save" type="primary" @click="submitForm('ruleForm')"
            >保存
            </el-button>
          </div>
        </el-form-item>
      </el-form>

    </div>
  </div>
</template>

<script>
export default {
  name: "guideYuwei",
  data() {
    return {
      ruleForm: [
        {
          name: "",
        },
      ],
      value_bangdincc: "",
      options: [
        {
          value: "",
          label: "",
        },
      ],
      value_show1: "",
      options_show1: [
        {
          value: "quyukongxianbowei",
          label: "区域空闲泊位",
        },
        {
          value: "fangixangzhishi",
          label: "方向指示",
        },
      ],
      value_show2: "",
      options_show2: [
        {
          value: "quyukongxianbowei",
          label: "区域空闲泊位",
        },
        {
          value: "fangixangzhishi",
          label: "方向指示",
        },
      ],
      value_show3: "",
      options_show3: [
        {
          value: "quyukongxianbowei",
          label: "区域空闲泊位",
        },
        {
          value: "fangixangzhishi",
          label: "方向指示",
        },
      ],
      value_show4: "",
      options_show4: [
        {
          value: "quyukongxianbowei",
          label: "区域空闲泊位",
        },
        {
          value: "fangixangzhishi",
          label: "方向指示",
        },
      ],
      value_show5: "",
      options_show5: [
        {
          value: "quyukongxianbowei",
          label: "区域空闲泊位",
        },
        {
          value: "fangixangzhishi",
          label: "方向指示",
        },
      ],
      value_show6: "",
      options_show6: [
        {
          value: "quyukongxianbowei",
          label: "区域空闲泊位",
        },
        {
          value: "fangixangzhishi",
          label: "方向指示",
        },
      ],
      dialogFormVisible: false,
      diaform: {
        qianzhui: "",
        topbgcolor: "132213",
        topfontcolor: "",
        centerbgcolor: "",
        centerfontcolor: "",
      },
    };
  },
  components: {},
  methods: {
    goBack() {
      // this.$router.back();
      this.$emit("goback");
    },
    openyuwei() {
      const h = this.$createElement;
      this.$msgbox({
        title: "自定义设置",
        message: h("p", null, [
          h("span", null, "内容可以是 "),
          h("h2", {style: "color: teal"}, "自定义窗口。。。"),
        ]),
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = "执行中...";
            setTimeout(() => {
              done();
              setTimeout(() => {
                instance.confirmButtonLoading = false;
              }, 300);
            }, 3000);
          } else {
            done();
          }
        },
      }).then((action) => {
        this.$message({
          type: "info",
          message: "action: " + action,
        });
      });
    },
  },
  mounted() {
  },
};
</script>

<style lang="less" scoped>
.add-parking-basic {
  background: white;
  border-radius: 5px;
  padding: 18px;
}

.tabs-wrap {
  position: relative;

  .back-button {
    position: absolute;
    right: 20px;
    top: 0;
    font-size: 15px;
    font-weight: normal;
    display: flex;
    flex-direction: row;
    align-items: center;
    line-height: 18px;

    i {
      font-size: 18px;
    }
  }
}
</style>
